@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
#brokerage-root {
  font-family: "Inter", sans-serif; }

.brokerage-font {
  font-family: "Inter", sans-serif; }

.brokerage-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  width: 100%;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0000009f;
  z-index: 10000000;
  font-family: "Inter", sans-serif; }

.brg-content {
  display: flex;
  margin-top: 16px; }

.brg-order-info {
  margin-left: 8px;
  flex: 1; }

.brokerage-wrapper button {
  font-weight: initial; }

.brokerage-wrapper textarea::placeholder {
  color: #344563;
  font-size: 14px; }

.brokerage-wrapper input {
  font-family: "Inter", sans-serif;
  appearance: auto !important;
  padding: 0;
  margin: 0;
  display: initial !important; }

.brokerage-wrapper p {
  padding: 0;
  margin: 0;
  line-height: initial !important; }

.brokerage-wrapper input,
textarea,
button {
  font-family: "Inter", sans-serif; }

@media screen and (max-width: 768px) {
  .brokerage-layout {
    height: 100dvh;
    overflow-y: scroll; } }

.brokerage-layout {
  position: relative;
  width: 815px;
  min-height: 600px !important;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: white;
  padding: 16px 36px 12px 36px;
  margin: 0 10px;
  transition: 0.3s;
  animation: fadeIn 0.5s; }

/* Cart Styles */
.brg-cart-block {
  min-height: 96px;
  box-sizing: border-box;
  margin-top: 10px;
  border: 1px solid #d2d6dc;
  border-radius: 4px;
  padding: 30px; }

.brg-title {
  color: #3667d6;
  font-size: 18px;
  font-weight: 600; }

.brg-cart-header {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 30px; }

.brg-cart-info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 27px; }

.brg-product-list {
  margin-top: 30px;
  gap: 10px;
  display: flex;
  flex-wrap: wrap; }

/* Step Styles */
.brg-credit-step {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  max-width: 320px;
  height: 56px;
  padding: 18px 24px;
  background: #f5fbff;
  border: 1px solid #7dabf8; }

.brg-step-count {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 50%;
  border: 1px solid #7dabf8; }

.brg-step-text {
  font-size: 14px;
  font-weight: 600; }

.brg-input:focus {
  border: 1px solid #3667d6; }

.brg-otp-container {
  display: flex;
  gap: 20px; }

/* Tooltip */
.brg-tooltip {
  max-width: 200px;
  background: #ffff !important;
  color: #6b778c !important;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1); }

.brg-tooltip::after {
  border-top-color: white !important; }

/* Media queries */
@media screen and (max-width: 920px) {
  .brg-input {
    max-width: 100% !important;
    width: 100%; } }

@media screen and (max-width: 768px) {
  .brokerage-layout {
    padding: 10px;
    border-radius: 0px;
    margin: 0px;
    overflow-y: auto; }
  .brg-content {
    display: block; }
  .brg-order-info {
    margin-left: 0px;
    margin-top: 12px; }
  .brokerage-wrapper {
    align-items: center;
    padding-top: 0px; }
  .brg-cart-header {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; }
  .brg-product-list {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px; }
  .brg-cart-block {
    min-height: auto; } }

@media screen and (max-width: 380px) {
  .brg-otp-container {
    gap: 10px; }
  .brg-otp-container div {
    width: 45px !important;
    height: 45px;
    text-align: center; }
  .brg-otp-container input {
    width: 100% !important;
    padding: 13px 5px !important; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* width */
.brokerage-layout::-webkit-scrollbar {
  width: 5px; }

/* Track */
.brokerage-layout::-webkit-scrollbar-track {
  background: #ffffff;
  width: 3px; }

/* Handle */
.brokerage-layout::-webkit-scrollbar-thumb {
  background: #bebebe; }
